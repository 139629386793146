
export const navigation: {
  label: string;
  to: string;
}[] = [

    {
      to: "https://seamlessprotocol.medium.com/seamless-protocol-terms-of-use-f9d75a855fb3",
      label: "Terms",
    },
    {
      to: "https://seamlessprotocol.medium.com/seamless-privacy-policy-2ebfda169143",
      label: "Privacy",
    },
    {
      to: "https://docs.seamlessprotocol.com",
      label: "Docs",
    },
    {
      to: "https://docs.seamlessprotocol.com/overview/faq",
      label: "FAQ",
    },
    {
      to: "https://bridge.base.org/deposit",
      label: "Base Bridge",
    },
  ]

export const FOOTER_NAVIGATION_ICONS = [
  {
    url: "https://t.me/seamless_protocol",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19" fill="none">
        <path d="M1.34876 7.92215L14.4534 2.52281C15.747 1.96038 20.134 0.160594 20.134 0.160594C20.134 0.160594 22.1587 -0.62681 21.99 1.28546C21.9338 2.07286 21.4838 4.82878 21.0339 7.80967L19.6278 16.6398C19.6278 16.6398 19.5153 17.9334 18.5592 18.1584C17.603 18.3834 16.0282 17.371 15.747 17.146C15.522 16.9773 11.5288 14.4464 10.0665 13.209C9.67276 12.8716 9.22281 12.1966 10.1227 11.4092C12.1475 9.55321 14.5659 7.24724 16.0282 5.78491C16.7032 5.10999 17.3781 3.53519 14.5659 5.44745L6.63562 10.7906C6.63562 10.7906 5.73573 11.353 4.04844 10.8468C2.36114 10.3406 0.39263 9.66569 0.39263 9.66569C0.39263 9.66569 -0.957206 8.82204 1.34876 7.92215Z" fill="#FAFAFA" />
      </svg>
    ),
  },
  {
    url: "https://discord.com/invite/Uye9jCVgUp",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M20.3303 4.22781C18.7767 3.50093 17.1156 2.97267 15.3789 2.67188C15.1656 3.05749 14.9164 3.57614 14.7446 3.98873C12.8985 3.71109 11.0693 3.71109 9.25716 3.98873C9.08539 3.57614 8.83055 3.05749 8.61536 2.67188C6.87681 2.97267 5.21376 3.50287 3.66019 4.23166C0.526643 8.96686 -0.322811 13.5845 0.101917 18.1365C2.18025 19.6885 4.19441 20.6313 6.17457 21.2483C6.66349 20.5754 7.09953 19.8601 7.47518 19.1063C6.75975 18.8344 6.07453 18.499 5.42707 18.1095C5.59884 17.9822 5.76686 17.8492 5.92918 17.7123C9.87819 19.5594 14.1689 19.5594 18.0707 17.7123C18.235 17.8492 18.403 17.9822 18.5728 18.1095C17.9235 18.5009 17.2364 18.8363 16.521 19.1082C16.8966 19.8601 17.3308 20.5774 17.8216 21.2502C19.8036 20.6333 21.8197 19.6905 23.898 18.1365C24.3964 12.8595 23.0467 8.28434 20.3303 4.22781ZM8.01318 15.337C6.82772 15.337 5.85555 14.2303 5.85555 12.8826C5.85555 11.535 6.80696 10.4264 8.01318 10.4264C9.21942 10.4264 10.1916 11.533 10.1708 12.8826C10.1727 14.2303 9.21942 15.337 8.01318 15.337ZM15.9867 15.337C14.8013 15.337 13.8291 14.2303 13.8291 12.8826C13.8291 11.535 14.7805 10.4264 15.9867 10.4264C17.193 10.4264 18.1651 11.533 18.1444 12.8826C18.1444 14.2303 17.193 15.337 15.9867 15.337Z" fill="#FAFAFA" />
      </svg>
    ),
  },
  {
    url: "https://twitter.com/seamlessfi",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M18.244 2.25H21.552L14.325 10.51L22.827 21.75H16.17L10.956 14.933L4.99 21.75H1.68L9.41 12.915L1.254 2.25H8.08L12.793 8.481L18.244 2.25ZM17.083 19.77H18.916L7.084 4.126H5.117L17.083 19.77Z" fill="#FAFAFA" />
      </svg>
    ),
  },
  {
    url: "https://github.com/seamless-protocol",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 md:w-5 md:h-5" viewBox="0 0 24 24">
        <path
          fill="white"
          d="M12 2A10 10 0 0 0 2 12c0 4.42 2.87 8.17 6.84 9.5c.5.08.66-.23.66-.5v-1.69c-2.77.6-3.36-1.34-3.36-1.34c-.46-1.16-1.11-1.47-1.11-1.47c-.91-.62.07-.6.07-.6c1 .07 1.53 1.03 1.53 1.03c.87 1.52 2.34 1.07 2.91.83c.09-.65.35-1.09.63-1.34c-2.22-.25-4.55-1.11-4.55-4.92c0-1.11.38-2 1.03-2.71c-.1-.25-.45-1.29.1-2.64c0 0 .84-.27 2.75 1.02c.79-.22 1.65-.33 2.5-.33c.85 0 1.71.11 2.5.33c1.91-1.29 2.75-1.02 2.75-1.02c.55 1.35.2 2.39.1 2.64c.65.71 1.03 1.6 1.03 2.71c0 3.82-2.34 4.66-4.57 4.91c.36.31.69.92.69 1.85V21c0 .27.16.59.67.5C19.14 20.16 22 16.42 22 12A10 10 0 0 0 12 2"
        />
      </svg>
    ),
  },
  {
    url: "https://warpcast.com/seamless",
    icon: (
      <svg width="20" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.77515 0H17.9645V20.1775H15.8817V10.9349H15.8612C15.631 8.38047 13.4842 6.3787 10.8698 6.3787C8.25545 6.3787 6.10861 8.38047 5.87842 10.9349H5.85799V20.1775H3.77515V0Z" fill="#FAFAFA" />
        <path d="M0 2.8639L0.846154 5.72781H1.56213V17.3136C1.20265 17.3136 0.911243 17.605 0.911243 17.9645V18.7456H0.781065C0.42159 18.7456 0.130178 19.037 0.130178 19.3964V20.1775H7.42012V19.3964C7.42012 19.037 7.12871 18.7456 6.76923 18.7456H6.63905V17.9645C6.63905 17.605 6.34764 17.3136 5.98817 17.3136H5.2071V2.8639H0Z" fill="#FAFAFA" />
        <path d="M16.0118 17.3136C15.6524 17.3136 15.3609 17.605 15.3609 17.9645V18.7456H15.2308C14.8713 18.7456 14.5799 19.037 14.5799 19.3964V20.1775H21.8698V19.3964C21.8698 19.037 21.5784 18.7456 21.2189 18.7456H21.0888V17.9645C21.0888 17.605 20.7973 17.3136 20.4379 17.3136V5.72781H21.1538L22 2.8639H16.7929V17.3136H16.0118Z" fill="#FAFAFA" />
      </svg>
    ),
  },
];
